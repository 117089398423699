&.index {

  .videos {
    .video {

      height: 300px;
      width: 100%;
      overflow: hidden;
      border: 1px solid #eee;
      margin-bottom: 30px;
      cursor: pointer;

      position: relative;

      .thumbnail {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        transition: all .5s;
      }
      .caption{
        position: absolute;
        bottom: 0;
        padding: 10px;
        transition: all .5s;
        z-index: 1;
        padding-left: 20px;


      }

      h3 {

        color: white;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: lighter;


      }
      h4{
        color: lightblue;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: lighter;
      }
      &:after{

        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 102%;
        background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.9)), color-stop(25%, rgba(0,0,0,0.6)), color-stop(45%, transparent));
        background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0, rgba(0,0,0,0.6) 25%, transparent 45%);
        background-image: -moz-linear-gradient(bottom, rgba(0,0,0,0.9) 0, rgba(0,0,0,0.6) 25%, transparent 45%);
        background-image: -o-linear-gradient(bottom, rgba(0,0,0,0.9) 0, rgba(0,0,0,0.6) 25%, transparent 45%);
        background-image: linear-gradient(to top, rgba(0,0,0,0.9) 0, rgba(0,0,0,0.6) 25%, transparent 45%);

      }

      &:hover, &:focus {

        .thumbnail {
          transform: scale(1.2);

        }

        .caption {
          bottom: 10px;
        }
      }
    }
  }
}