// assets/css/global.scss

// customize some Bootstrap variables
$primary: darken(#428bca, 0%);
body{
  background-color: red;
}

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
.nav-link{
  color: black !important;
}
h1{
  font-weight: lighter;
  margin-bottom: 20px;
  font-size: 28px;
  @include media-breakpoint-down(sm)
  {
    font-size: 28px;
  }
}
.sign-up{
  @include media-breakpoint-up(sm) {
    border-radius: 30px;
    min-width: 120px;
    padding: 0 10px;
    border: 1px solid $primary;
    margin-left: 10px;
    a{
      color: $primary !important;
    }
  }
}
.navbar-brand{
  width: 60px;
}
.footer{

  margin-top: 50px;
  .copyright{
    margin-bottom: 10px;
  }
}
.action-btn{
  a{
    margin-right: 20px;
  }
}
.payment-logos{

  img{
    width: 50px;
  }
  margin-bottom: 10px;
}
.btn-cta{
  min-width: 200px;
}
.form-check
{
  margin-bottom: 10px;
}
.navbar-brand{
  width: 100px !important;
  height: auto !important;
  margin: 0 !important;
}